<template>
    <div>

        <!-- search input -->
        <div class="custom-search d-flex justify-content-end" v-if="searchFilter">
            <b-form-group>
                <div class="d-flex align-items-center">
                  <label class="mr-1">{{ $t('message.seachLabel') }}</label>
                  <b-form-input
                      v-model="filterTerm"
                      :placeholder=" $t('message.SearchPlaceholder')"
                      type="text"
                      class="d-inline-block"
                  />
                </div>
            </b-form-group>
        </div>
        <!-- Table -->
        <b-table
            id="table-orders"
            :sticky-header="stickyHeader"
            :no-border-collapse="noCollapse"
            :items="rowsTable"
            :fields="fields"
            :per-page="pageLength"
            :current-page="currentPage"
            :searchFilter="searchFilter"
            
        >

            <!-- Slot: Table Column -->
            <template #head()="scope">
                <div class="text-nowrap">
                    {{ $t(scope.label) }}
                </div>
            </template>
            
            <!-- Slot: Table Row -->

            <template
                v-for="slot in Object.keys($scopedSlots)"
                v-slot:[slot]="props"
            >
                <slot v-bind="props" :name="slot" /> 
            </template>
            
        </b-table>

        <div class="d-flex justify-content-between flex-wrap">

            <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                {{ $t('message.pagelength') }}
                </span>
                <b-form-select
                v-model="pageLength"
                :options="['10','20','30']"
                class="mx-1"
                />
                <span class="text-nowrap">  {{ $t('message.of') }} {{ rowsTable.length }} {{ $t('message.pageText2') }} </span>
            </div>

            <b-pagination
                aria-controls="table-orders"
                v-model="currentPage"
                :total-rows="rowsTable.length"
                :per-page="pageLength"
                class="mt-1 mb-0"
                :value="1"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
            >
                <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18"/>
                </template>
                <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
            </b-pagination>
        </div>
    </div>
</template>

<script>
import { BPagination, BTable, BFormSelect, BDropdownItem, BDropdown, BFormInput, BFormGroup} from 'bootstrap-vue'

export default {
  components: {
    BPagination,
    BTable,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    BFormInput,
    BFormGroup,
  },
  props: {
    rows: {
      type: Array,
      default: null,
    },
    fields: {
      type: Array,
      default: null,
    },
    noCollapse: {
      type: Boolean,
      default: true,
    },
    stickyHeader: {
      type: String,
      default: true,
    },
    searchFilter:{
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      filterTerm: '',
      pageLength: 10,
      currentPage: 1,
    }
  },
  computed: {
     rowsTable() {
      if(this.filterTerm){
        let rowsFiltered = [];
        rowsFiltered = this.rows.filter(o => Object.keys(o).some(k => String(o[k]).toLowerCase().includes(this.filterTerm.toLowerCase())));
        return rowsFiltered
      }
      else{
        return this.rows;
      }
    },
  },
  
}
</script>


<style lang="scss">

.b-table-sticky-column, .table-b-table-default{
  background-color: #ffff !important;
  border: 1px solid #ebe9f1 !important;
   .dark-layout &{
      background-color: #283046 !important;
      color: #d0d2d6 !important;
    }
}
.table td{
  border: 1px solid #ebe9f1;
}
.table thead th{
  text-transform: none;
  font-size: 15px;
  background-color: #ffff !important;
  border-bottom: 0;
  border-top: 0;
  .dark-layout &{
      background-color: #283046 !important;
    }
}
.b-table-sticky-column.fix-1{
  padding: 9px 52px !important;
}
.b-table-sticky-column.fix-2{
  left: 187px !important;
  padding: 9px 20px !important;
}
.b-table-sticky-column.fix-3{
  left: 301px !important;
  padding: 9px 30px !important;
}
.table.b-table th{
  outline: 1px solid #ebe9f1;
  .dark-layout &{
      outline-color: #3b4253  !important;
    }
}
.pagination .page-item.active{
  height: 33px;
}


</style>
